const colors = {
  red: "#E50914",
  green: "#25D060",
  blue: "#2A6FC9",
  yellow: "#F6E329",
  pink: "#F7B1BB",
  purple: "#B19CD9",
  dark: "#18191A",
  white: "#FFFFFF",
};

export default colors;
